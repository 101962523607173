import { AnchorMock } from '~/types/mock'

const Anchors: AnchorMock[] = [
  {
    anchor: 'Services',
  },
  {
    anchor: 'Industries',
  },
  {
    anchor: 'Business Type',
  },
  {
    anchor: 'Process',
  },
  {
    anchor: 'Benefits',
  },
  {
    anchor: 'Tech Stack',
  },
  {
    anchor: 'Requirements',
  },
  {
    anchor: 'Cases',
  },
  {
    anchor: 'Free Quote',
  },
  {
    anchor: 'FAQ',
  },
]

export default Anchors
