import { getImage, StaticImage } from 'gatsby-plugin-image'

import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksItem from '~/components/items/DiscoverWorksItem'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/custom-software-development/Anchors'
import CustomSoftwareBenefits from '~/mock/services/custom-software-development/CustomSoftwareBenefits'
import CustomSoftwareBusinesses from '~/mock/services/custom-software-development/CustomSoftwareBusinesses'
import CustomSoftwareDevExpertise from '~/mock/services/custom-software-development/CustomSoftwareDevExpertise'
import CustomSoftwareDevServices from '~/mock/services/custom-software-development/CustomSoftwareDevServices'
import CustomSoftwareExpertise from '~/mock/services/custom-software-development/CustomSoftwareExpertise'
import CustomSoftwareFAQ from '~/mock/services/custom-software-development/CustomSoftwareFAQ'
import CustomSoftwareProcess from '~/mock/services/custom-software-development/CustomSoftwareProcess'
import CustomSoftwareRequirements from '~/mock/services/custom-software-development/CustomSoftwareRequirements'
import CustomSoftwareWhyChoose from '~/mock/services/custom-software-development/CustomSoftwareWhyChoose'
import { fileToImageLikeData } from '~/utils'
import AwardsServicesSection from '~/views/Services/components/AwardsServicesSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesRequirementsSoftware from '~/views/Services/components/ServicesRequirementsSoftware'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'
import useCustomSoftwareStaticQuery from '~/views/Services/CustomSofware/useCustomSoftwareStaticQuery'

import * as containerStyles from './CustomSoftware.module.scss'

const clientName = 'Celso Prado'

const CustomSoftwareDevelopment = () => {
  const query = useCustomSoftwareStaticQuery()

  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const clientRef = getImage(fileToImageLikeData(query.clientRef))
  const portfolioTradeaboat = getImage(
    fileToImageLikeData(query.portfolioTradeaboat),
  )
  const quoteCodicaMaxPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaMaxPhoto),
  )

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Custom Software"
        title="Development Services"
        description="We build high-performing custom software for businesses that need more than a standard solution."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Custom Software Development Services"
        breadcrumbCurrentPath="/services/custom-software-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.servicesCustomSoftwareSection}
      >
        <HeadSection
          position="center"
          title="Custom software development services we offer"
          description="Codica’s team has extensive experience creating custom software products, whether single-page applications or complex enterprise platforms. We are proud to bring our best expertise to build custom software that we specialize in."
          descriptionLarge
        />
        <Service3DIconCard dataContent={CustomSoftwareDevServices} />
      </section>

      <section
        id="industries"
        className={containerStyles.industriesCustomSoftwareSection}
      >
        <HeadSection
          position="center"
          title="We bring expertise across different industries"
          description="During our work in software development for various industries, we have accumulated vast experience. We are glad to share it with our clients. Our experts consult clients in every product development phase, keeping all processes transparent. So, the clients receive the product they expect to get."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={CustomSoftwareExpertise} />
      </section>

      <CallToAction
        title="Do you have a great idea for a software product?"
        subtitle="We will help to bring it to life."
        classNameForGA="servicesCTA_1"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section
        id="business-type"
        className={containerStyles.businessCustomSoftwareSection}
      >
        <HeadSection
          position="center"
          title="We guarantee the growth for businesses of any size"
          description="Are you a startupper or an owner of an established business? Want you enterprise to grow? We will help you develop and scale your solution with our efficient custom development services. We know exactly what tasks you need to solve and help with this using our proven technologies."
          descriptionLarge
        />
        <ServicesIndustriesCard
          isColTwo
          dataContent={CustomSoftwareBusinesses}
        />
      </section>

      <section
        id="process"
        className={containerStyles.processCustomSoftwareSection}
      >
        <HeadSection
          position="center"
          title="Custom software development process"
        />
        <ServicesProcess dataContent={CustomSoftwareProcess} />
        <SubtractBlock isTop />
      </section>

      <section
        id="benefits"
        className={containerStyles.benefitsCustomSoftwareSection}
      >
        <HeadSection
          position="center"
          title="Benefits of the custom software development service"
          description="In the modern competitive world, originality and uniqueness will make your business stand out from the competition. At Codica, we are sure that custom product development will play a vital role in taking your enterprise to the next level of growth."
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={CustomSoftwareBenefits} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.techStackCustomSoftwareSection}
      >
        <HeadSection
          position="center"
          title="Best software development tech stack to build custom solutions"
          description="Over the years, we have picked a list of tools to help us build high-quality custom digital products that solve business challenges. Together with our efficient development process, these tools enable us to keep the cost of custom software development services within our client’s expectations."
          titleLarge
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section className={containerStyles.caseStudyCustomSoftwareSection}>
        <HeadSection position="center" title="Featured case study" />
        <DiscoverWorksItem
          key={3}
          gradient="gradientTradeaboat"
          image={portfolioTradeaboat}
          header="Custom software development for the boat marketplace"
          title="This is an Australian-based online website to sell and buy new or used boats and marine equipment. Site visitors can also discover informative and entertaining content on this boat-selling platform."
          resultsLeftTitle="480%"
          resultsLeft="increase in lead generation performance"
          resultsRightTitle="null"
          resultsRight="Modern, robust, and fast-loading solution"
          link="online-marketplace-for-boats"
          review={[
            '“One of the main projects I took on when accepting this role was to redevelop two of the business’s various websites. The Codica team seemed to have a clear process from the beginning.',
            <div key="featuredCase" style={{ paddingTop: 10 }} />,
            'We are in verticals where market leaders have hundreds of in-house developers and multi-billion dollars in annual revenue. And despite all of that, our lean team, with the help from Codica, is growing its market share year after year. They are like my secret weapon.”',
          ]}
          clientPhoto={
            <StaticImage
              src="../../../assets/images/avatars/clients/client-tradervs.png"
              alt={`${clientName} | Codica`}
              title={clientName}
              width={50}
              height={50}
            />
          }
          clientName={clientName}
          clientPosition="Digital General Manager at the Adventures Group"
        />
      </section>

      <section
        id="requirements"
        className={containerStyles.requirementCustomSoftwareSection}
      >
        <HeadSection
          position="center"
          title="Requirements for advanced software"
          description="Based on our extensive experience, the Codica team will help you build custom software of high quality that meets modern web standards."
          titleLarge
          descriptionLarge
        />
        <ServicesRequirementsSoftware
          dataContent={CustomSoftwareRequirements}
        />
      </section>

      <CallToAction
        title="Looking for a reliable custom software development company?"
        subtitle="Tell us about your project"
        classNameForGA="servicesCTA_2"
        linkName="Get in touch"
        link="/contacts/"
      />

      <section
        id="cases"
        className={containerStyles.casesCustomSoftwareSection}
      >
        <HeadSection
          position="center"
          title="Cases"
          description="Our clients like partnering with us for their leading digital projects."
        />
        <SuccessStoriesSliderSection btnName="View all our works" />
      </section>

      <section className={containerStyles.whyChooseCustomSoftwareSection}>
        <HeadSection
          position="center"
          title="Why hire Codica custom software development company"
          titleLarge
        />
        <CompanyWhyHireBlock dataContent={CustomSoftwareWhyChoose} />
      </section>

      <section className={containerStyles.expertiseCustomSoftwareSection}>
        <HeadSection
          position="center"
          title="Our credible experience in software development"
        />
        <ServicesDevExpertiseCompany dataContent={CustomSoftwareDevExpertise} />
      </section>

      <section className={containerStyles.testimonialsCustomSoftwareSection}>
        <HeadSection
          position="center"
          title="We are proud of having the skilled and reliable development team"
          description="The Codica agency provides full-cycle custom software development services to perfectly empower your business with a unique software product to suit your needs."
          titleLarge
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          content={[
            {
              text: 'We believe in approaching each project as a potential game-changer and because of that, we support our clients comprehensively, going far beyond the code. Together with an enthusiastic team, this approach helps us build the best custom product for your business.',
            },
            {
              text: 'Having experienced business analysts, project managers, software developers, UX / UI designers and QA engineers on board, we are able to create a software product of any scale and fully cover your needs in custom software application development.',
            },
          ]}
        />
        <div
          className={
            containerStyles.testimonialsCustomSoftwareSection__testimonial
          }
        >
          <Testimonial
            image={quoteCodicaMaxPhoto}
            name="Maksym"
            position="Tech Lead & Rails expert at Codica"
            quote={[
              {
                text: '“I am proud to say that our team truly cares about each project we are working on. It is our priority to make sure that custom products and apps we create bring the best results to our clients.',
              },
              {
                text: 'To achieve this, when providing customized software development services, we begin with careful analysis of business requirements. We further align the requirements with possible solutions, and advise on the most efficient way to proceed.”',
              },
            ]}
          />
        </div>
      </section>

      <section className={containerStyles.ourAchievementsCustomSoftwareSection}>
        <HeadSection
          position="center"
          title="Our achievements"
          description="Our expertise in web development is confirmed by ratings and research companies."
        />
        <AwardsServicesSection />
      </section>

      <section className={containerStyles.clientQuotesCustomSoftwareSection}>
        <HeadSection
          position="center"
          title="Our clients say"
          description="Genuine feedback from our clients is precious to us and inspires further progress. We invite you to explore the transformative business experiences of people who entrusted their projects to Codica."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Leon Zinger"
            location="Israel"
            position="Founder at RefDental"
            image={clientRef}
            quote={[
              {
                text: '“What we like most about working with Codica is their deep understanding of our business needs. They quickly grasped our initial idea and delivered our Minimum Viable Product within the specified time and budget. Thanks to this, we could get user feedback fast”',
              },
            ]}
            link="dental-imaging-management-system"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s build a great custom product together!"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our custom product development company’s experts will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={CustomSoftwareFAQ} />
      </section>
    </MainLayout>
  )
}

export default CustomSoftwareDevelopment
