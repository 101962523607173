import { RequirementsSoftwareMock } from '~/types/mock'

const CustomSoftwareRequirements: RequirementsSoftwareMock[] = [
  {
    icon: 'spriteIcon_56',
    title: 'User-friendly',
    list: [
      { item: 'Clean UI and great UX' },
      { item: 'Easy navigation' },
      { item: 'Responsive layout' },
    ],
  },
  {
    icon: 'spriteIcon_15',
    title: 'Fast-loading',
    list: [
      { item: 'Open in less than 3 seconds' },
      { item: 'Prefetch soon-to-be-needed resources' },
      { item: 'CDN, cache, compressing HTML/CSS' },
    ],
  },
  {
    icon: 'spriteIcon_91',
    title: 'Scalable',
    list: [
      {
        item: 'Pre-built static resources',
      },
      {
        item: (
          <>
            Follow
            <a
              href="https://web.dev/apply-instant-loading-with-prpl/"
              target="_blank"
              className="mx5"
              key="linkPRPL"
            >
              PRPL Pattern
            </a>
            (Push, Render, Pre-cache, Lazy-load)
          </>
        ),
      },
      {
        item: 'DevOps best standards',
      },
    ],
  },
  {
    icon: 'spriteIcon_37',
    title: 'Secure',
    list: [
      { item: 'SSL by default' },
      { item: 'Vulnerabilities free' },
      { item: 'Secure from main attacks (Injection, XSS, CSRF)' },
    ],
  },
  {
    icon: 'spriteIcon_32',
    title: 'Seo-friendly',
    list: [
      { item: 'Technical SEO and code optimization' },
      { item: 'On-page optimization' },
      { item: 'SEO-friendly site architecture' },
    ],
  },
  {
    icon: 'spriteIcon_20',
    title: 'PWA by default',
    list: [
      { item: 'Mobile-friendly' },
      { item: 'Connectivity independent' },
      { item: 'Discoverable and installable' },
    ],
  },
  {
    icon: 'spriteIcon_60',
    title: 'Engaging',
    list: [
      { item: 'Push notifications' },
      { item: 'Conversion optimized' },
      { item: 'Tracking pixels enabled' },
    ],
  },
  {
    icon: 'spriteIcon_11',
    title: 'Manageable',
    list: [
      {
        item: 'Content management system (CMS) enabled by default',
      },
      {
        item: 'Maintenance and support',
      },
      {
        item: 'Regular updates if needed',
      },
    ],
  },
]

export default CustomSoftwareRequirements
