// extracted by mini-css-extract-plugin
export var benefitsCustomSoftwareSection = "H_gl";
export var businessCustomSoftwareSection = "H_gj";
export var caseStudyCustomSoftwareSection = "H_gn";
export var casesCustomSoftwareSection = "H_gq";
export var clientQuotesCustomSoftwareSection = "H_gx";
export var expertiseCustomSoftwareSection = "H_gs";
export var industriesCustomSoftwareSection = "H_gh";
export var ourAchievementsCustomSoftwareSection = "H_gw";
export var processCustomSoftwareSection = "H_gk";
export var requirementCustomSoftwareSection = "H_gp";
export var servicesCustomSoftwareSection = "H_gg";
export var techStackCustomSoftwareSection = "H_gm";
export var testimonialsCustomSoftwareSection = "H_gt";
export var testimonialsCustomSoftwareSection__testimonial = "H_gv";
export var whyChooseCustomSoftwareSection = "H_gr";