import { BaseMock } from '~/types/mock'

const CustomSoftwareWhyChoose: BaseMock[] = [
  {
    title: 'Save costs and launch quickly',
    description:
      'Our custom software development process is efficient and fully transparent, allowing us deliver your software product on time and on budget.',
  },
  {
    title: 'Get expert recommendations',
    description:
      'Having built numerous custom software products, our skilled team offers the best solution for your business challenges.',
  },
  {
    title: 'Enthusiastic team that cares',
    description:
      'Our experienced and result-oriented team values flexibility and communication and focuses on building long-lasting work relationships.',
  },
  {
    title: 'The right solution for your needs',
    description:
      'The client’s success is our priority. We carefully align your business requirements with software functionality to ensure the best value.',
  },
  {
    title: 'Best industry standards',
    description:
      'As a top software custom development company, we adapt our development and quality assurance process according to the best standards.',
  },
  {
    title: 'Support after launch',
    description:
      'Our services don’t stop when your project goes live. Our trustworthy support team will ensure your software runs seamlessly.',
  },
]

export default CustomSoftwareWhyChoose
