import { FAQ } from '~/types/data-array'

const CustomSoftwareFAQ: FAQ[] = [
  {
    question: 'What is custom software development?',
    answer:
      'It is the process of building an original and unique software product that meets a specific business’s needs.',
  },
  {
    question: 'How much does it cost to build custom software?',
    answer:
      'On average, the cost of custom product development can vary from $50,000 to $150,000. Still, this range can change per various aspects like software complexity, design requirements, different integrations, and so on.',
  },
  {
    question: 'How long does it take to create a custom software product?',
    answer:
      'A custom software development firm spends approximately 4-9 months from idea research to completion of testing and the final release. Careful engineering is a must to provide a high-quality and reliable software project.',
  },
]

export default CustomSoftwareFAQ
