import { DevExpertiseCompanyMock } from '~/types/mock'

const CustomSoftwareDevExpertise: DevExpertiseCompanyMock[] = [
  {
    title: '50+',
    text: 'successful projects built',
    classNameText: 'maxWidth180',
  },
  {
    title: '9+',
    text: 'years as a trusted custom software development services provider',
    classNameText: 'maxWidth270',
  },
  {
    title: '60+',
    text: 'skilled specialists on board',
    classNameText: 'maxWidth180',
  },
  {
    title: '$56+',
    text: 'mln generated by our startup clients',
    classNameText: 'maxWidth180',
  },
]

export default CustomSoftwareDevExpertise
