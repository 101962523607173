import { HomeSpecification } from '~/types/mock'

const CustomSoftwareExpertise: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description:
      'We build custom online marketplace solutions that provide multi-vendor platforms the highest chance of success.',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'At Codica, we can create unique eCommerce solution that boosts conversion rates and brings profit to you as an owner.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description:
      'We develop efficient custom travel software for clients who need more than out-of-the-box solutions, making their business profitable and scalable.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description:
      'Our software development team of skilled professionals provides custom automotive solutions of high quality that attract customers and increase sales.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description:
      'You don’t need any technical skills to build a profitable insurance solution - the Codica team can handle that. So, you will receive a robust and secure digital product.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'Bring your idea of the recruiting solution to life with our top-notch custom development services. With us, you will get a smart and competitive product.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'Building a rental custom solution with Codica will be the best choice regarding efficiency, customization, and customer experience.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Media',
    description:
      'Our skilled development team will work with you to make custom media software that fits all your needs, regardless of any business size.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'You can rely on our high-quality custom product development services. With us, you will get an innovative healthcare solution that improves patient care and generates a profit.',
    link: '/case-studies/dental-imaging-management-system/',
  },
]

export default CustomSoftwareExpertise
