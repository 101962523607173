import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const CustomSoftwareProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Research and discovery',
    descr: (
      <>
        <Link
          to="/services/product-discovery/"
          className="mr5"
          key="keyProductDiscovery"
        >
          Product discovery
        </Link>
        is the first stage of custom software services. It enables our team to
        understand your business needs and address them with custom software.
      </>
    ),
  },
  {
    num: '02',
    title: 'Product design (UX and UI)',
    descr: (
      <>
        Our designers provide quality
        <Link to="/services/ui-ux-design/" className="mx5" key="keyDesign">
          UI/UX design services
        </Link>
        based on your solution’s aims. We’ll develop a modern custom app that
        your end-users will adore.
      </>
    ),
  },
  {
    num: '03',
    title: 'Product (MVP) development',
    descr:
      'Our custom business software developers create your solution following the latest industry standards. Your product will be robust, secure, and scalable.',
  },
  {
    num: '04',
    title: 'Optimization and QA',
    descr: (
      <>
        Thorough
        <Link to="/services/quality-assurance/" className="mx5" key="keyQA">
          QA testing services
        </Link>
        of each software product helps us ensure the high performance and
        excellence of all the projects made by Codica.
      </>
    ),
  },
  {
    num: '05',
    title: 'Deployment monitoring',
    descr:
      'We monitor software once it’s deployed. As one of the custom software development companies with full cycle services, we will take care of your solution and can support it.',
  },
]

export default CustomSoftwareProcess
