import { ExpertiseSectionMock } from '~/types/mock'

const CustomSoftwareDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'Software development consulting',
    description:
      'Our quality experts will analyze your business idea, conceptualize your project, and offer tech solutions to help you get the best value from your product.',
  },
  {
    icon: 'sprite3DIcon_18',
    title: 'Online marketplace development',
    link: '/services/online-marketplace-development/',
    description:
      'We’re a top provider of custom online marketplaces for various sectors of businesses that drive massive traffic and satisfy plenty of users.',
  },
  {
    icon: 'sprite3DIcon_13',
    title: 'SaaS development',
    link: '/services/saas-development/',
    description:
      'With our SaaS development services, you will get a custom SaaS solution enabling you to provide cutting-edge technologies to your online environment.',
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'Mobile app development',
    link: '/services/mobile-app-development/',
    description:
      'We have vast experience in mobile application development. So, we can develop a mobile solution that meets your unique business needs and workflows for any domain.',
  },
  {
    icon: 'sprite3DIcon_15',
    title: 'MVP Development',
    link: '/services/mvp-development/',
    description:
      'As skilled progressive web app developers, we can create a PWA solution for your business. Thus, you can enhance brand awareness and connect with customers.',
  },
  {
    icon: 'sprite3DIcon_22',
    title: 'PWA Development',
    link: '/services/progressive-web-apps-development/',
    description:
      'As skilled progressive web app developers, we can create a PWA solution for your business. Thus, you can enhance brand awareness and connect with customers.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'Custom software design',
    description:
      'Our experienced designers create software designs that are unique, usable, and visually appealing for the fast growth of your business.',
  },
]

export default CustomSoftwareDevServices
