import { ExpertiseSectionMock } from '~/types/mock'

const CustomSoftwareBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_27',
    title: 'Personalization',
    description:
      'Template-based software is made to serve a vast range of companies at once. In contrast, custom software development solutions are built in accordance with your product specifications. Custom products address your personal business tasks and leverage your benefits.',
  },
  {
    icon: 'spriteIcon_63',
    title: 'Security',
    description:
      'Custom software is reliable as it’s built following the security regulations and standards. Our experts test software solutions properly to lower the chances of intrusion and ensure your software’s reliability and high performance.',
  },
  {
    icon: 'spriteIcon_101',
    title: 'Scalability',
    description:
      'The main point of starting any business is its future growth, agree? Template software products may not be able to handle the heavy load. But we develop custom software with all the modifications in mind. Thus, your software will scale as the business grows.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Profitability',
    description:
      'By choosing the custom development services, you, as an owner of the business, will receive more in return. Custom product development is a reasonable investment for businesses because this service will save your budget in the long run.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'Independence',
    description:
      'In contrast to out-of-the-box solutions, with custom product development, you get exclusive ownership rights that give you full control of your software. That means you are able to make any modifications to it and use its potential with regard to your business needs.',
  },
  {
    icon: 'spriteIcon_14',
    title: 'Seamless integration',
    description:
      'Our custom software development services enable the implementation of tailored tools and systems. So, businesses can streamline their operations, automate workflows, and increase the overall solution’s productivity.',
  },
]

export default CustomSoftwareBenefits
