import { ExpertiseSectionMock } from '~/types/mock'

const CustomSoftwareBusinesses: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'Startups',
    description:
      'When it comes to building innovative software, you should act fast to stay ahead of competitors. And we are eager to give a hand! We will help you check the viability of your idea and launch your project quickly with our proven custom development services.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'Small businesses',
    description:
      'At Codica, we will assist you in meeting your concerns and all the core needs of your business. We will take into consideration your business’s hidden risks and fine points. So, we provide the best technical solutions that help your business evolve.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'Mid-scale businesses',
    description:
      'Codica’s skilled team will create a visually appealing and highly-responsive custom software project for your business, applying the top-notch industry tools and practices. We will be ready to support and further improve your existing software.',
  },
  {
    icon: 'sprite3DIconSmall_19',
    title: 'Enterprises',
    description:
      'Our mature custom software specialists are here to assist you with digital transformation. We conduct a deep analysis of your issues. Thus, we will help you automate business processes and reduce expenses with a custom solution crafted especially for your needs.',
  },
]

export default CustomSoftwareBusinesses
